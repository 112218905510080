import { SVGProps } from 'react';

const Hamburger = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={24}
    height={24}
    fill="current"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 5h12M2 12h20M10 19h12"
      stroke="current"
      fill="none"
      strokeWidth={2}
    />
  </svg>
);

export default Hamburger;
