import { SVGProps } from 'react';

const DeliveryBox = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_292_195837)">
      <path
        d="M1.76978 4.28805C1.6313 4.26618 1.6313 4.06682 1.76978 4.04496V4.04496C3.45615 3.77869 4.77881 2.45603 5.04508 0.769659V0.769659C5.06695 0.63118 5.26631 0.63118 5.28817 0.769659V0.769659C5.55444 2.45603 6.8771 3.77869 8.56347 4.04496V4.04496C8.70195 4.06682 8.70195 4.26618 8.56347 4.28805V4.28805C6.8771 4.55432 5.55444 5.87698 5.28817 7.56335V7.56335C5.26631 7.70183 5.06695 7.70183 5.04508 7.56335V7.56335C4.77881 5.87698 3.45615 4.55432 1.76978 4.28805V4.28805Z"
        stroke="#042D49"
        strokeWidth="1.5"
      />
      <path
        d="M34 4.09106H36.091M38.182 4.09106H36.091M36.091 4.09106V2.00008M36.091 4.09106L36.091 6.18205"
        stroke="#042D49"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.5 38.0843C20.5718 38.6202 19.4282 38.6202 18.5 38.0843L6.49999 31.1561C5.57179 30.6202 5 29.6298 5 28.558V14.7016C5 13.6298 5.5718 12.6394 6.5 12.1035L18.5 5.17534C19.4282 4.63945 20.5718 4.63945 21.5 5.17534L33.5 12.1035C34.4282 12.6394 35 13.6298 35 14.7016V28.558C35 29.6298 34.4282 30.6202 33.5 31.1561L21.5 38.0843Z"
        stroke="#042D49"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M5 12.9695L18.5 20.7637C19.4282 21.2996 20.5718 21.2996 21.5 20.7637L35 12.9695"
        stroke="#042D49"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M20 21.6299V38.9504"
        stroke="#042D49"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M25.3228 7.38257L11.8228 15.1768C10.8945 15.7127 10.3228 16.7031 10.3228 17.7749V22.9711C10.3228 24.0429 10.8946 25.0332 11.8228 25.5691L13.1768 26.3509C13.8435 26.7358 14.6768 26.2547 14.6768 25.4849V20.2887C14.6768 19.2169 15.2486 18.2265 16.1768 17.6906L29.6769 9.8964"
        stroke="#042D49"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_292_195837">
        <rect width="40" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default DeliveryBox;
