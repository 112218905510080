import { SVGProps } from 'react';

const Play = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={24}
    height={24}
    fill="current"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={12} cy={12} r={12} fill="#FFFFFF" stroke="none" />
    <path d="M9 15.231v-6.6a1 1 0 0 1 1.454-.89l5.94 3.024a1 1 0 0 1 .061 1.748l-5.94 3.575A1 1 0 0 1 9 15.23Z" />
  </svg>
);

export default Play;
