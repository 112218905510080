import { SVGProps } from 'react';

const Warning = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    strokeWidth={1.5}
    fill="inherit"
    stroke="inherit"
    {...props}
  >
    <rect
      x="1.93934"
      y="16"
      width="19.5"
      height="19.5"
      rx="4.75"
      transform="rotate(-45 1.93934 16)"
      stroke="inherit"
      strokeWidth="inherit"
      fill="none"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2915 9C14.7174 9 14.2612 9.48227 14.293 10.0555L14.6819 17.0555C14.7113 17.5854 15.1496 18 15.6804 18H15.7884C16.3191 18 16.7574 17.5854 16.7868 17.0555L17.1757 10.0555C17.2076 9.48227 16.7514 9 16.1773 9H15.2915ZM15.7344 23C16.5628 23 17.2344 22.3284 17.2344 21.5C17.2344 20.6716 16.5628 20 15.7344 20C14.9059 20 14.2344 20.6716 14.2344 21.5C14.2344 22.3284 14.9059 23 15.7344 23Z"
      fill="inherit"
      stroke="none"
    />
  </svg>
);

export default Warning;
