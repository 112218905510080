import { SVGProps } from 'react';

const MagicWand = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={80}
    height={80}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M56.437 32.005c-.258-.04-.258-.413 0-.454a7.35 7.35 0 0 0 6.114-6.114c.041-.259.413-.259.454 0a7.35 7.35 0 0 0 6.114 6.114c.259.041.259.413 0 .454a7.35 7.35 0 0 0-6.114 6.114c-.04.259-.413.259-.454 0a7.35 7.35 0 0 0-6.114-6.114ZM38.047 10.844c-.138-.022-.138-.221 0-.243a3.937 3.937 0 0 0 3.275-3.276c.022-.138.222-.138.243 0a3.938 3.938 0 0 0 3.276 3.276c.138.021.138.22 0 .243a3.938 3.938 0 0 0-3.276 3.275c-.021.139-.22.139-.243 0a3.937 3.937 0 0 0-3.275-3.275Z"
      fill="none"
      stroke="#26D9D8"
      strokeWidth={1.5}
    />
    <path
      d="m47.516 45.25-3.686-6.384a1 1 0 0 0-1.366-.366L39.866 40a1 1 0 0 0-.366 1.366l3.596 6.229m4.42-2.345 15.786 27.342a1 1 0 0 1-.366 1.366l-2.598 1.5a1 1 0 0 1-1.366-.366L43.096 47.595m4.42-2.345-4.42 2.345"
      fill="none"
      stroke="#042D49"
      strokeWidth={1.5}
    />
    <path
      d="M24.762 11.656C19.334 15.31 8.635 24.403 9.256 31.54c.62 7.136 9.992 7.875 14.6 7.352 6.863-.802 22.137-4.28 28.33-11.775 6.193-7.494-1.297-8.437-5.816-7.972-6.078.579-19.513 3.876-25.68 16.053m7.385-25.893s1.887-1.2 3.328-1.85M19.863 41.817c-.776 7.117 7.49 9.281 18.017 4.126"
      fill="none"
      stroke="#042D49"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </svg>
);

export default MagicWand;
