import { SVGProps } from 'react';

const Cross = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={24}
    height={24}
    strokeWidth={1.5}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.657 6.343 6.343 17.657M17.657 17.657 6.343 6.343"
      stroke="current"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Cross;
