import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={80}
    height={80}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m30 57.063 14.868-8.829a82.462 82.462 0 0 0 13.316-9.856 28.941 28.941 0 0 0 7.275-10.35l.112-.273a13.072 13.072 0 0 0-20.455-15.019l-4.476 3.73a1 1 0 0 1-1.28 0l-4.477-3.73a13.071 13.071 0 0 0-20.455 15.019l.113.273a28.941 28.941 0 0 0 7.275 10.35 82.462 82.462 0 0 0 13.316 9.856L50 57.062 53 59"
      fill="none"
      stroke="#042D49"
      strokeWidth={1.5}
    />
    <path
      d="M66 63a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
      fill="none"
      stroke="#042D49"
      strokeWidth={1.5}
    />
    <path
      d="m12.403 62.375-1.624-3.248 8.347-4.64a7.687 7.687 0 0 1 10.392 2.874v0a7.686 7.686 0 0 1-2.814 10.5l-8.2 4.735-1.771-2.644"
      fill="none"
      stroke="#26D9D8"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx={59}
      cy={63}
      r={3}
      fill="none"
      stroke="#042D49"
      strokeWidth={1.5}
    />
    <path
      d="M9 37v3.75M9 49v-4M14 43h-2.5M4 43h2.5"
      fill="none"
      stroke="#042D49"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M66.037 9.775c-.187-.03-.187-.298 0-.328a5.303 5.303 0 0 0 4.41-4.41c.03-.187.298-.187.328 0a5.303 5.303 0 0 0 4.41 4.41c.187.03.187.298 0 .328a5.303 5.303 0 0 0-4.41 4.41c-.03.187-.298.187-.328 0a5.303 5.303 0 0 0-4.41-4.41Z"
      fill="none"
      stroke="#26D9D8"
      strokeWidth={1.5}
    />
  </svg>
);

export default SvgComponent;
