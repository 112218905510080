import { SVGProps } from 'react';

const Bandaid = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={80}
    height={80}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={23.59}
      y={5.705}
      width={71}
      height={26}
      rx={13}
      transform="rotate(45 23.59 5.705)"
      fill="none"
      stroke="#042D49"
      strokeWidth={1.5}
    />
    <rect
      x={35.61}
      y={23.383}
      width={29}
      height={18}
      rx={9}
      transform="rotate(45 35.61 23.383)"
      fill="none"
      stroke="#26D9D8"
      strokeWidth={1.5}
    />
    <circle
      cx={49.046}
      cy={58.031}
      r={0.5}
      transform="rotate(45 49.046 58.031)"
      fill="#042D49"
      stroke="#042D49"
    />
    <circle
      cx={57.531}
      cy={49.546}
      r={0.5}
      transform="rotate(45 57.531 49.546)"
      fill="#042D49"
      stroke="#042D49"
    />
    <circle
      cx={58.945}
      cy={58.031}
      r={0.5}
      transform="rotate(45 58.945 58.031)"
      fill="#042D49"
      stroke="#042D49"
    />
    <circle
      r={0.5}
      transform="scale(-1 1) rotate(-45 25.968 41.283)"
      fill="#042D49"
      stroke="#042D49"
    />
    <circle
      r={0.5}
      transform="scale(-1 1) rotate(-45 10.372 46.658)"
      fill="#042D49"
      stroke="#042D49"
    />
    <circle
      r={0.5}
      transform="scale(-1 1) rotate(-45 14.018 36.334)"
      fill="#042D49"
      stroke="#042D49"
    />
  </svg>
);

export default Bandaid;
