import { SVGProps } from 'react';

const Calendar = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={80}
    height={80}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14 28v29a8 8 0 0 0 8 8h37a8 8 0 0 0 8-8V28m-53 0v-5a8 8 0 0 1 8-8h5M14 28h36m17 0v-5a8 8 0 0 0-8-8v0m8 13h-8M35 15v-1a2 2 0 0 0-2-2v0a2 2 0 0 0-2 2v4m4-3h8m-8 0h-8m16 0v-1a2 2 0 0 0-2-2v0a2 2 0 0 0-2 2v4m4-3h8m0 0v-1a2 2 0 0 0-2-2v0a2 2 0 0 0-2 2v4m4-3h8m0 0v-1a2 2 0 0 0-2-2v0a2 2 0 0 0-2 2v4m-28-3v-1a2 2 0 0 0-2-2v0a2 2 0 0 0-2 2v4"
      fill="none"
      stroke="#042D49"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x={23}
      y={35}
      width={7}
      height={7}
      rx={2}
      fill="none"
      stroke="#042D49"
      strokeWidth={1.5}
    />
    <rect
      x={23}
      y={47}
      width={7}
      height={7}
      rx={2}
      fill="none"
      stroke="#042D49"
      strokeWidth={1.5}
    />
    <rect
      x={37}
      y={35}
      width={7}
      height={7}
      rx={2}
      fill="none"
      stroke="#042D49"
      strokeWidth={1.5}
    />
    <rect
      x={37}
      y={47}
      width={7}
      height={7}
      rx={2}
      fill="none"
      stroke="#042D49"
      strokeWidth={1.5}
    />
    <rect
      x={51}
      y={35}
      width={7}
      height={7}
      rx={2}
      fill="none"
      stroke="#26D9D8"
      strokeWidth={1.5}
    />
    <rect
      x={51}
      y={47}
      width={7}
      height={7}
      rx={2}
      fill="none"
      stroke="#042D49"
      strokeWidth={1.5}
    />
  </svg>
);

export default Calendar;
