import { SVGProps } from 'react';

const Handshake = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_292_195827)">
      <path
        d="M15.869 10.8686L15.3386 11.3989C15.0458 11.106 15.0458 10.6311 15.3386 10.3382L15.869 10.8686ZM24.1315 10.8686L23.6012 10.3382L24.1315 10.8686ZM22.1216 12.8785L21.5913 12.3482L22.1216 12.8785ZM35.1811 6.17073C31.4384 2.42799 25.3702 2.428 21.6275 6.17073L20.5668 5.11007C24.8953 0.781551 31.9133 0.781548 36.2418 5.11007L35.1811 6.17073ZM35.1811 19.7244C38.9238 15.9816 38.9238 9.91346 35.1811 6.17073L36.2418 5.11007C40.5703 9.43858 40.5703 16.4565 36.2418 20.785L35.1811 19.7244ZM21.6275 6.17073L16.3993 11.3989L15.3386 10.3382L20.5668 5.11007L21.6275 6.17073ZM16.3993 10.3382L18.4093 12.3482L17.3486 13.4089L15.3386 11.3989L16.3993 10.3382ZM21.5913 12.3482L23.6012 10.3382L24.6619 11.3989L22.652 13.4088L21.5913 12.3482ZM23.6012 10.3382L24.4698 9.4696L25.5305 10.5302L24.6619 11.3989L23.6012 10.3382ZM24.6587 10.335L30.5273 16.1331L29.473 17.2001L23.6044 11.4021L24.6587 10.335ZM34.1184 20.787L35.1811 19.7244L36.2418 20.785L35.1791 21.8477L34.1184 20.787ZM18.4093 12.3482C19.288 13.2269 20.7126 13.2269 21.5913 12.3482L22.652 13.4088C21.1875 14.8734 18.8131 14.8734 17.3486 13.4089L18.4093 12.3482Z"
        fill="#042D49"
      />
      <path
        d="M19.9998 6.66658L18.9737 5.6404C14.938 1.60477 8.39498 1.60477 4.35936 5.6404C0.323733 9.67602 0.323731 16.2191 4.35936 20.2547L5.83317 21.7285"
        stroke="#042D49"
        strokeWidth="1.5"
      />
      <rect
        x="1.6665"
        y="24.7139"
        width="6.66667"
        height="5"
        rx="2.5"
        transform="rotate(-45 1.6665 24.7139)"
        stroke="#042D49"
        strokeWidth="1.5"
      />
      <rect
        x="5"
        y="29.7139"
        width="6.66667"
        height="5"
        rx="2.5"
        transform="rotate(-45 5 29.7139)"
        stroke="#042D49"
        strokeWidth="1.5"
      />
      <rect
        x="10"
        y="33.0474"
        width="6.66667"
        height="5"
        rx="2.5"
        transform="rotate(-45 10 33.0474)"
        stroke="#042D49"
        strokeWidth="1.5"
      />
      <rect
        x="15.8335"
        y="35"
        width="6.66667"
        height="5"
        rx="2.5"
        transform="rotate(-45 15.8335 35)"
        stroke="#042D49"
        strokeWidth="1.5"
      />
      <path
        d="M28.3311 15.0005L34.6898 21.3592C35.7958 22.4652 35.7958 24.2584 34.6898 25.3644L34.5414 25.5128C33.5174 26.5368 31.8571 26.5368 30.8331 25.5128V25.5128M30.8331 25.5128L30.9862 26.6436C31.324 29.1384 29.1497 31.2473 26.6664 30.8334V30.8334M30.8331 25.5128L25.1136 19.7933M26.6664 30.8334L26.4394 31.7414C26.0831 33.1668 24.8024 34.1667 23.3331 34.1667V34.1667M26.6664 30.8334L20.3515 24.5182"
        stroke="#042D49"
        strokeWidth="1.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_292_195827">
        <rect width="40" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Handshake;
