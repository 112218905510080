import { SVGProps } from 'react';

type InfoProps = SVGProps<SVGSVGElement> & {
  iColor?: string;
};

const Info = ({
  iColor = '#042D49',
  width = 12,
  height = 12,
  ...props
}: InfoProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    stroke="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <circle cx={16} cy={16} r={10.667} stroke="#042D49" strokeWidth={1.5} />
    <path
      stroke={iColor}
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M16 15.515v4.364M16 11.152v.97"
    />
  </svg>
);

export default Info;
