import { SVGProps } from 'react';

const Stopwatch = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={24}
    height={24}
    fill="current"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fill="none" stroke="none" d="M0 0h24v24H0z" />
    <circle
      cx={12}
      cy={14}
      r={9}
      stroke="current"
      fill="none"
      strokeWidth={1.5}
    />
    <path
      d="M8 2h4m4 0h-4m0 0v3M12 9v7"
      stroke="current"
      fill="none"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      opacity={0.4}
      d="m15 10-3 6"
      stroke="current"
      fill="none"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      opacity={0.2}
      d="m17 13-5 2.887"
      stroke="current"
      fill="none"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </svg>
);

export default Stopwatch;
