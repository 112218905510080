import { SVGProps } from 'react';

const Tick = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={24}
    height={24}
    fill="none"
    stroke="current"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m7 12.5 3.636 3.5L17 9"
      stroke="current"
      fill="none"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Tick;
