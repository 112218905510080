import { SVGProps } from 'react';

const World = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={24}
    height={24}
    fill="current"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11 3.055A9.001 9.001 0 0 0 3.936 16M11 3.055A9 9 0 0 1 20.777 14M11 3.055A2.945 2.945 0 0 0 13.945 6h.747c.17 0 .308.138.308.308V7a4 4 0 0 1-4 4H9.5A2.5 2.5 0 0 0 7 13.5 2.5 2.5 0 0 1 4.5 16h-.564m0 0A9 9 0 0 0 12 21a8.963 8.963 0 0 0 4-.936m0 0C14.007 17.648 15.726 14 18.86 14h1.917M16 20.064A9.017 9.017 0 0 0 20.777 14"
      stroke="current"
      fill="none"
      strokeWidth={1.5}
    />
  </svg>
);

export default World;
