import { SVGProps } from 'react';

const Mountains = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={80}
    height={80}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m4 67.5 13.5-27.25M76 67.5 56.918 27m-11.085 0L36.85 45.233c-1.032 2.095-3.957 2.26-5.217.293L28.25 40.25M45.833 27l2.915-5.916c1.111-2.254 4.334-2.226 5.405.047L56.918 27m-11.085 0 .736 1.02a2.456 2.456 0 0 0 3.42.56l.267-.192a2.02 2.02 0 0 1 2.437.067v0a2.02 2.02 0 0 0 2.578-.044L56.918 27M17.5 40.25l2.49-5.103c1.06-2.175 4.128-2.263 5.312-.152l2.948 5.255m-10.75 0 .417 1.043a2.683 2.683 0 0 0 3.69 1.403l.244-.122a2.213 2.213 0 0 1 2.218.139v0c1.051.7 2.475.38 3.125-.703l1.056-1.76"
      fill="none"
      stroke="#042D49"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="M22.5 31v-3m0 0v-4.631a1 1 0 0 1 1.555-.832l3.936 2.624c.742.494.515 1.638-.358 1.813L22.5 28Z"
      fill="none"
      stroke="#26D9D8"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="m32 70.5 9.176-7.816c1.835-1.564 1.17-4.544-1.156-5.178l-2.724-.743a2 2 0 0 1-1.092-3.106l1.29-1.775a2 2 0 0 0-.202-2.59L35 47m25.5 23.5.24-1.119c1.469-6.855-3.376-13.456-10.356-14.11v0a1.849 1.849 0 0 1-1.425-2.773l.462-.792c.8-1.372.094-3.13-1.433-3.567L37 45"
      fill="none"
      stroke="#042D49"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="M66 7v3.75M66 19v-4M71 13h-2.5M61 13h2.5"
      fill="none"
      stroke="#042D49"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Mountains;
