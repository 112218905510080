import { SVGProps } from 'react';

const DownArrow = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    viewBox="0 0 22 27"
    width={22}
    height={27}
    strokeWidth={1.5}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    aria-label="Down arrow"
  >
    <path
      d="M10.7568 2V25M10.7568 25L2 15.8M10.7568 25L20 15.8"
      stroke="current"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DownArrow;
