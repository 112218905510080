import { SVGProps } from 'react';

const Flask = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={80}
    height={80}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m19.707 49.396 10.786-17.14a6 6 0 0 0 .922-3.195V15.913a.163.163 0 0 0-.164-.163 2.178 2.178 0 0 1-2.178-2.178v-.197A2.375 2.375 0 0 1 31.448 11h16.323a2.375 2.375 0 0 1 2.375 2.375v.171a2.204 2.204 0 0 1-2.203 2.204.138.138 0 0 0-.138.138v13.124a6 6 0 0 0 .97 3.271l11.127 17.113m-40.195 0-5.92 9.408C11.272 62.8 14.144 68 18.865 68H60.94c4.762 0 7.626-5.28 5.03-9.27l-6.069-9.334m-40.195 0h40.195"
      fill="none"
      stroke="current"
      strokeWidth={1.5}
    />
    <path
      d="M58.437 29.005c-.258-.04-.258-.413 0-.454a7.35 7.35 0 0 0 6.114-6.114c.041-.259.413-.259.454 0a7.35 7.35 0 0 0 6.114 6.114c.259.041.259.413 0 .454a7.35 7.35 0 0 0-6.114 6.114c-.04.259-.413.259-.454 0a7.35 7.35 0 0 0-6.114-6.114ZM10.45 19.492c-.18-.028-.18-.289 0-.317a5.146 5.146 0 0 0 4.28-4.28c.028-.181.289-.181.318 0a5.145 5.145 0 0 0 4.28 4.28c.18.028.18.289 0 .317a5.145 5.145 0 0 0-4.28 4.28c-.029.181-.29.181-.318 0a5.145 5.145 0 0 0-4.28-4.28Z"
      fill="none"
      stroke="#26D9D8"
      strokeWidth={1.5}
    />
    <circle
      r={2.586}
      transform="scale(-1 1) rotate(-45 34.37 66.078)"
      fill="none"
      stroke="current"
      strokeWidth={1.5}
    />
    <circle
      r={1.784}
      transform="scale(-1 1) rotate(-45 22.718 72.895)"
      fill="none"
      stroke="current"
      strokeWidth={1.5}
    />
    <circle
      r={0.5}
      transform="scale(-1 1) rotate(-45 23.835 59.955)"
      fill="none"
      stroke="current"
    />
  </svg>
);

export default Flask;
