import { SVGProps } from 'react';

const Search = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={24}
    height={24}
    fill="current"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M17.267 18.428a9.58 9.58 0 0 1-13.55 0 9.58 9.58 0 1 1 13.55 0Zm0 0 4.081 4.081"
        stroke="current"
        fill="none"
        strokeWidth={1.5}
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="none" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default Search;
