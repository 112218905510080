import { SVGProps } from 'react';

const Empowerment = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={80}
    height={80}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20 46.194v0a3.06 3.06 0 0 0 3.06 3.06h1.835a4.896 4.896 0 0 0 4.896-4.896v-8.567a4.896 4.896 0 0 0-4.896-4.895v0A4.896 4.896 0 0 0 20 35.79v10.403Zm0 0v10.693a10 10 0 0 0 1.425 5.145l5.918 9.864"
      fill="none"
      stroke="#042D49"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <rect
      x={29.791}
      y={28.448}
      width={9.791}
      height={20.806}
      rx={4.896}
      fill="none"
      stroke="#042D49"
      strokeWidth={1.5}
    />
    <path
      d="M49.373 38.85v-7.955a4.896 4.896 0 0 0-9.791 0V41.91M59.164 38.85v-5.507a4.896 4.896 0 0 0-9.791 0v5.508h9.791Z"
      fill="none"
      stroke="#042D49"
      strokeWidth={1.5}
    />
    <path
      d="M35.299 66.388c0-14.075 13.462-15.298 13.462-15.298v-2.805a.255.255 0 0 0-.255-.255h-4.334a4.59 4.59 0 0 1-4.59-4.59v0a4.59 4.59 0 0 1 4.59-4.59h14.151A2.677 2.677 0 0 1 61 41.529v11.74a21 21 0 0 1-4.2 12.6l-4.979 6.64"
      fill="none"
      stroke="#042D49"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M58.437 18.005c-.258-.041-.258-.413 0-.454a7.35 7.35 0 0 0 6.114-6.114c.041-.259.413-.259.454 0a7.35 7.35 0 0 0 6.114 6.114c.259.04.259.413 0 .454a7.35 7.35 0 0 0-6.114 6.114c-.04.259-.413.259-.454 0a7.35 7.35 0 0 0-6.114-6.114ZM41.037 12.774c-.187-.029-.187-.297 0-.327a5.303 5.303 0 0 0 4.41-4.41c.03-.187.298-.187.328 0a5.303 5.303 0 0 0 4.41 4.41c.187.03.187.298 0 .328a5.303 5.303 0 0 0-4.41 4.41c-.03.187-.298.187-.328 0a5.303 5.303 0 0 0-4.41-4.41Z"
      fill="none"
      stroke="#26D9D8"
      strokeWidth={1.5}
    />
    <path
      d="M15 12v3.75M15 24v-4M20 18h-2.5M10 18h2.5"
      fill="none"
      stroke="#042D49"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Empowerment;
