import { SVGProps } from 'react';

const Scale = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M26.9173 21.1153C26.9173 23.898 24.6615 26.1538 21.8787 26.1538H10.5423C7.52776 26.1538 5.08398 23.7101 5.08398 20.6955V11.4583C5.08398 8.44377 7.52776 6 10.5423 6H21.4589C24.4735 6 26.9173 8.44378 26.9173 11.4583V21.5353M11.5475 26.1538C10.635 26.1538 9.7599 25.7914 9.11469 25.1462C8.14687 24.1783 7.60315 22.8657 7.60315 21.497V11.4583C7.60315 8.44378 10.0469 6 13.0615 6M16.4813 18.9808L19.0005 21.5M14.7409 17.3366L14.321 16.9167M17.2603 16.4968L19.3596 18.5961M12.6417 11.4584C12.6417 10.2989 13.5816 9.35901 14.7411 9.35901H19.7795C20.939 9.35901 21.8789 10.2989 21.8789 11.4584C21.8789 12.6178 20.939 13.5577 19.7795 13.5577H14.7411C13.5816 13.5577 12.6417 12.6178 12.6417 11.4584Z"
      stroke="#042D49"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </svg>
);

export default Scale;
