import { SVGProps } from 'react';

// Do not reuse this icon due to the NHS logo guidelines, we will be replacing existing usage with a new icon
const BackedByNHS = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={80}
    height={80}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M30.397 10.597a3 3 0 0 1 3.219-.863l3.324 1.128a3 3 0 0 0 2.291-.15L42.38 9.16a3 3 0 0 1 3.304.435l2.64 2.314a3 3 0 0 0 2.174.738L54 12.418a3 3 0 0 1 2.886 1.667l1.553 3.148a3 3 0 0 0 1.726 1.513l3.324 1.13a3 3 0 0 1 2.029 2.643l.23 3.504a3 3 0 0 0 1.015 2.058l2.64 2.315a3 3 0 0 1 .862 3.22l-1.128 3.324a3 3 0 0 0 .15 2.29l1.553 3.15a3 3 0 0 1-.436 3.304l-2.314 2.64a3 3 0 0 0-.738 2.173l.23 3.503a3 3 0 0 1-1.667 2.886l-3.148 1.554a3 3 0 0 0-1.514 1.725l-1.129 3.325a3 3 0 0 1-2.644 2.029l-3.503.23a3 3 0 0 0-2.059 1.015l-2.315 2.639a3 3 0 0 1-3.22.863l-3.324-1.129a3 3 0 0 0-2.29.15L37.62 70.84a3 3 0 0 1-3.304-.435l-2.64-2.314a3 3 0 0 0-2.174-.738L26 67.582a3 3 0 0 1-2.886-1.666l-1.553-3.149a3 3 0 0 0-1.726-1.514l-3.324-1.128a3 3 0 0 1-2.03-2.644l-.23-3.504a3 3 0 0 0-1.014-2.058l-2.64-2.315a3 3 0 0 1-.862-3.22l1.128-3.324a3 3 0 0 0-.15-2.29L9.16 37.62a3 3 0 0 1 .435-3.304l2.315-2.64a3 3 0 0 0 .738-2.173L12.418 26a3 3 0 0 1 1.667-2.887l3.148-1.553a3 3 0 0 0 1.514-1.726l1.129-3.324a3 3 0 0 1 2.644-2.029l3.503-.23a3 3 0 0 0 2.059-1.015l2.315-2.639Z"
      stroke="#042D49"
      fill="none"
      strokeWidth={1.5}
    />
    <path
      d="m47.522 54.549 10.625-2.847m-13.04 3.494-1.932.518"
      stroke="#042D49"
      fill="none"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="m34.451 53.91 2.908-.779-.873-9.912-2.502.67.443 4.955.313 1.22-.135.037-4.002-5.306-2.907.78.873 9.911 2.502-.67-.43-4.958-.313-1.22.136-.037 3.987 5.31ZM38.906 52.717l2.502-.67-.33-3.826 3.651-.978.33 3.825 2.502-.67-.874-9.912-2.501.67.333 3.68-3.651.977-.334-3.679-2.501.67.873 9.913ZM53.188 46.86c-.879.236-1.455.086-1.802-.125a1.371 1.371 0 0 1-.494-.491l-2.542.68c.777 1.604 2.443 2.897 5.35 2.118 2.542-.681 3.57-2.637 3.116-4.328-.373-1.393-1.792-1.766-3.129-1.886-1.33-.093-2.65-.102-2.817-.724-.09-.338.12-.742.931-.96.758-.202 1.204-.105 1.488.037a.89.89 0 0 1 .38.333l2.542-.681c-.443-.896-1.65-2.747-4.868-1.885-2.42.649-3.488 2.558-3.054 4.181.377 1.407 1.77 1.787 3.126 1.873 1.336.12 2.633.092 2.803.727.142.528-.34.947-1.03 1.132Z"
      fill="#26D9D8"
      stroke="none"
    />
    <path
      d="m18.797 43.61 3.086-.826c1.434-.384 2.007-1.377 1.739-2.376-.168-.626-.552-.915-.91-1.033a1.716 1.716 0 0 0-.648-.087l-.012-.044c.16-.099.296-.219.416-.363.185-.245.343-.632.203-1.154-.233-.869-1.123-1.376-2.375-1.04l-3.13.838 1.63 6.086Zm.314-4.043-.326-1.217 1.651-.443c.522-.14.852.051.946.399.093.348-.098.678-.62.818l-1.651.443Zm.675 2.52-.373-1.39 1.696-.454c.66-.178 1.037.048 1.142.439.105.391-.109.774-.77.951l-1.695.455ZM23.856 39.88c.384 1.434 1.541 2.102 2.671 1.799a1.756 1.756 0 0 0 1.205-1.068l.044-.012.15.565 1.218-.326-1.188-4.434-1.217.327.151.565-.043.011a1.756 1.756 0 0 0-1.577-.323c-1.13.303-1.799 1.46-1.414 2.895Zm1.26-.339c-.224-.834.12-1.43.737-1.595.661-.177 1.256.167 1.48 1.002.224.834-.12 1.43-.78 1.606-.618.166-1.213-.178-1.437-1.013ZM29.03 38.492c.35 1.305 1.664 2.07 3.02 1.707.974-.26 1.425-.838 1.628-1.368.123-.303.175-.634.167-.976l-1.26.338a1.322 1.322 0 0 1-.116.403 1.065 1.065 0 0 1-.698.56c-.6.16-1.27-.219-1.48-1.001-.21-.783.181-1.446.781-1.607.391-.105.673.006.884.136.115.08.215.175.302.291l1.26-.337a2.609 2.609 0 0 0-.65-.758c-.44-.357-1.128-.63-2.076-.375-1.356.363-2.11 1.683-1.761 2.987ZM34.756 39.334l1.217-.326-.466-1.738.652-.175 1.596 1.436 1.391-.373-2.205-1.971.887-2.428-1.348.361-.73 1.967-.522.14-.885-3.304-1.217.326 1.63 6.085Z"
      fill="#042D49"
      stroke="none"
    />
    <path
      d="M38.246 36.023c.35 1.304 1.663 2.07 3.02 1.707.956-.256 1.416-.799 1.659-1.283.137-.289.206-.587.225-.9l-1.304.35a1.02 1.02 0 0 1-.139.317c-.13.175-.33.368-.721.473-.565.151-1.163-.201-1.41-.74l3.477-.932-.07-.261c-.346-1.295-1.585-2.091-2.976-1.718-1.356.363-2.11 1.683-1.761 2.987Zm1.176-.827c.011-.478.3-.966.865-1.117a1.08 1.08 0 0 1 1.264.546l-2.13.57ZM43.287 34.672c.384 1.435 1.542 2.103 2.672 1.8a1.756 1.756 0 0 0 1.204-1.068l.044-.012.151.566 1.218-.326-1.631-6.086-1.217.326.594 2.217-.044.012a1.757 1.757 0 0 0-1.577-.323c-1.13.303-1.798 1.46-1.414 2.894Zm1.26-.337c-.223-.835.12-1.43.738-1.595.66-.177 1.256.166 1.48 1 .223.835-.12 1.43-.781 1.608-.617.165-1.213-.179-1.436-1.013ZM51.539 34.837l1.217-.326-.151-.565.043-.011a1.756 1.756 0 0 0 1.577.323c1.13-.303 1.799-1.46 1.414-2.895-.384-1.435-1.541-2.103-2.672-1.8a1.756 1.756 0 0 0-1.204 1.068l-.044.012-.593-2.217-1.218.326 1.631 6.085Zm.623-2.543c-.224-.834.12-1.43.78-1.606.618-.166 1.213.178 1.437 1.013.224.834-.12 1.43-.737 1.595-.661.177-1.256-.167-1.48-1.002ZM55.178 29.11l2.362 4.12.956-.257-.035 1.78 1.26-.338.152-6.563-1.26.338-.048 3.647-.348.093-1.778-3.157-1.26.337Z"
      fill="#042D49"
      stroke="none"
    />
  </svg>
);

export default BackedByNHS;
