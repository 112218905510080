import { SVGProps } from 'react';

const Pin = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={24}
    height={24}
    fill="current"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.812 21.934 5.8 15.327a7.783 7.783 0 1 1 12.4 0l-5.012 6.607a1.492 1.492 0 0 1-2.376 0ZM12 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
    />
  </svg>
);

export default Pin;
