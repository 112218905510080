import { SVGProps } from 'react';

const Medal = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={80}
    height={80}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M55 49c0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15 0-8.284 6.716-15 15-15m7.059 1.761a15.064 15.064 0 0 1 7.059 8.158"
      fill="none"
      stroke="#042D49"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="M31.25 29.648A21.145 21.145 0 0 0 19 48.866C19 60.538 28.402 70 40 70s21-9.462 21-21.134c0-8.53-5.02-15.88-12.25-19.218m-17.5 0A20.81 20.81 0 0 1 40 27.73c3.123 0 6.086.686 8.75 1.917m-17.5 0v-2.814a2.625 2.625 0 0 1 2.625-2.625v0m14.875 5.439v-2.814a2.625 2.625 0 0 0-2.625-2.625v0m0 0h-12.25m12.25 0L57 6.5M46.125 24.209 39.5 16M32 6.5l7.5 9.5m-5.625 8.209L19 6.5M39.5 16 45 6.5"
      fill="none"
      stroke="#042D49"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="M61.437 26.005c-.258-.04-.258-.413 0-.454a7.35 7.35 0 0 0 6.114-6.114c.041-.259.413-.259.454 0a7.35 7.35 0 0 0 6.114 6.114c.259.041.259.413 0 .454a7.35 7.35 0 0 0-6.114 6.114c-.04.259-.413.259-.454 0a7.35 7.35 0 0 0-6.114-6.114ZM7.037 66.775c-.187-.03-.187-.298 0-.328a5.303 5.303 0 0 0 4.41-4.41c.03-.187.298-.187.328 0a5.303 5.303 0 0 0 4.41 4.41c.187.03.187.298 0 .328a5.303 5.303 0 0 0-4.41 4.41c-.03.187-.298.187-.328 0a5.302 5.302 0 0 0-4.41-4.41Z"
      fill="none"
      stroke="#26D9D8"
      strokeWidth={1.5}
    />
    <path
      d="M11 18v3.75M11 30v-4M16 24h-2.5M6 24h2.5"
      fill="none"
      stroke="#042D49"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Medal;
