import { SVGProps } from 'react';

const TwitterLogo = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={24}
    height={24}
    fill="current"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    aria-label="Instagram logo"
  >
    <path
      stroke="none"
      d="M19.953 7.92c.012.176.012.353.012.53 0 5.422-4.128 11.675-11.675 11.675v-.003A11.617 11.617 0 0 1 2 18.282a8.24 8.24 0 0 0 6.073-1.7 4.108 4.108 0 0 1-3.834-2.85 4.09 4.09 0 0 0 1.853-.07A4.104 4.104 0 0 1 2.8 9.64v-.052c.57.318 1.21.494 1.863.514a4.108 4.108 0 0 1-1.27-5.48 11.646 11.646 0 0 0 8.456 4.288 4.108 4.108 0 0 1 6.992-3.742 8.234 8.234 0 0 0 2.607-.997 4.118 4.118 0 0 1-1.804 2.27A8.16 8.16 0 0 0 22 5.793a8.337 8.337 0 0 1-2.047 2.126Z"
    />
  </svg>
);

export default TwitterLogo;
