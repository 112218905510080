import { SVGProps } from 'react';

const Microscope = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={80}
    height={80}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={24}
      y={65}
      width={32}
      height={5}
      rx={2.5}
      fill="none"
      stroke="#042D49"
      strokeWidth={1.5}
    />
    <path
      d="M40 13c-14.36 0-26 11.64-26 26s11.64 26 26 26c7.62 0 14.745-3.778 19.5-9"
      fill="none"
      stroke="#042D49"
      strokeWidth={1.5}
    />
    <rect
      x={49}
      y={53}
      width={20}
      height={3}
      rx={1.5}
      fill="none"
      stroke="#042D49"
      strokeWidth={1.5}
    />
    <path
      d="M49.702 29 38 8.732a2 2 0 1 1 3.464-2L53.166 27m-3.464 2L51 31.249a1 1 0 0 0 1.366.366l1.732-1a1 1 0 0 0 .366-1.366L53.166 27m-3.464 2 3.464-2"
      fill="none"
      stroke="#042D49"
      strokeWidth={1.5}
    />
    <path fill="none" stroke="#26D9D8" d="M53.5 49.5h12v1h-12z" />
  </svg>
);

export default Microscope;
