import { SVGProps } from 'react';

type TickInCircleProps = SVGProps<SVGSVGElement> & {
  width?: number;
  height?: number;
};

const TickInCircle = ({
  width = 24,
  height = 24,
  ...props
}: TickInCircleProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    fill="none"
    stroke="current"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx={width / 2}
      cy={height / 2}
      r={(width * 5) / 12}
      stroke="current"
      fill="none"
      strokeWidth={1.5}
    />
    <path
      d={`M${(width * 7) / 24} ${height / 2} L${(width * 11) / 24} ${
        (height * 16) / 24
      } L${(width * 17) / 24} ${(height * 9) / 24}`}
      stroke="current"
      fill="none"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TickInCircle;
