/**
 * Relatively similar to TickInCircle (but not similar enough to reuse TickInCircle)
 * Allows us to change the fill color of the component
 */
const TickInCircleWithFill = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="current">
    <path
      fill="current"
      fillRule="evenodd"
      d="M11.75 19.75a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm3.78-9.47a.75.75 0 1 0-1.06-1.06l-3.97 3.97-1.345-1.345a.75.75 0 0 0-1.06 1.06l1.592 1.593c.449.449 1.177.449 1.626 0l4.217-4.218Z"
      clipRule="evenodd"
    />
  </svg>
);

export default TickInCircleWithFill;
