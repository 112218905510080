import { SVGProps } from 'react';

const Lifebuoy = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_459_269444)">
      <path
        d="M38.8281 4.87789C38.8281 2.83109 37.1689 1.17188 35.1221 1.17188C34.319 1.17188 33.5757 1.42758 32.9688 1.86172C32.3618 1.42758 31.6185 1.17188 30.8154 1.17188C28.7686 1.17188 27.1094 2.83109 27.1094 4.87789C27.1094 9.03367 32.9685 11.7186 32.9688 11.7188C32.969 11.7186 38.8281 9.03367 38.8281 4.87789Z"
        stroke="#042D49"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M16.1328 38.8281C24.3955 38.8281 31.0938 32.1299 31.0938 23.8672C31.0938 15.6045 24.3955 8.90625 16.1328 8.90625C7.87012 8.90625 1.17188 15.6045 1.17188 23.8672C1.17188 32.1299 7.87012 38.8281 16.1328 38.8281Z"
        stroke="#042D49"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M16.1328 30.8984C20.0161 30.8984 23.1641 27.7504 23.1641 23.8672C23.1641 19.9839 20.0161 16.8359 16.1328 16.8359C12.2496 16.8359 9.10156 19.9839 9.10156 23.8672C9.10156 27.7504 12.2496 30.8984 16.1328 30.8984Z"
        stroke="#042D49"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M12.6165 29.9575L8.65234 36.8237"
        stroke="#042D49"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M10.0424 27.3835L3.17627 31.3477"
        stroke="#042D49"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M22.2231 27.3835L29.0893 31.3477"
        stroke="#042D49"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M19.6489 29.9578L23.6131 36.8239"
        stroke="#042D49"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M19.6489 17.7768L23.6131 10.9106"
        stroke="#042D49"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M22.2231 20.3509L29.0892 16.3867"
        stroke="#042D49"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M10.0424 20.3509L3.17627 16.3867"
        stroke="#042D49"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M12.6165 17.7767L8.65234 10.9106"
        stroke="#042D49"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_459_269444">
        <rect width="40" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Lifebuoy;
