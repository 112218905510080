import { SVGProps } from 'react';

const SugarCubes = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={80}
    height={80}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m21.975 42.013 7.71 9.647m-7.71-9.647v0a2.987 2.987 0 0 1 1.814-.353l8.79 1.081a5 5 0 0 1 3.382 1.953l5.331 7.071c.38.505.591 1.117.602 1.748v0m-19.919-11.5v0c-.437.117-.81.403-1.037.796l-5.313 9.202a4 4 0 0 0 .286 4.428l5.13 6.715c.32.42.721.772 1.178 1.036l.174.1m7.291-12.63 12.21 1.853m-12.21-1.853-7.291 12.63m19.5-10.777v0c.118.438.056.903-.17 1.296L36.41 64.01a4 4 0 0 1-3.978 1.967l-8.38-1.085a4.003 4.003 0 0 1-1.486-.503l-.173-.1"
      fill="none"
      stroke="#042D49"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m41.417 36.847 12.21-1.853m-12.21 1.853v0c.011-.632.221-1.244.602-1.748l5.33-7.071a5 5 0 0 1 3.383-1.953l8.79-1.081a2.987 2.987 0 0 1 1.814.353v0m-19.919 11.5v0c-.117.437-.055.903.17 1.295l5.314 9.203a4 4 0 0 0 3.978 1.967l8.38-1.086a3.998 3.998 0 0 0 1.486-.502l.173-.1m-7.291-12.63 7.709-9.647m-7.71 9.647 3.647 6.315m4.063-15.962v0c.437.117.81.403 1.037.795l5.313 9.203a4 4 0 0 1-.286 4.428l-5.13 6.715a4 4 0 0 1-1.178 1.035l-.174.1m0 0-1.823-3.157"
      fill="none"
      stroke="#26D9D8"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m21.975 13.013 7.71 9.647m-7.71-9.647v0a2.987 2.987 0 0 1 1.814-.353l8.79 1.081a5 5 0 0 1 3.382 1.953l5.331 7.071c.38.505.591 1.117.602 1.748v0m-19.919-11.5v0c-.437.117-.81.403-1.037.796l-5.313 9.202a4 4 0 0 0 .286 4.428l5.13 6.715c.32.42.721.772 1.178 1.036l.174.1m7.291-12.63 12.21 1.853m-12.21-1.853-7.291 12.63m19.5-10.777v0c.118.438.056.903-.17 1.295l-5.313 9.203a4 4 0 0 1-3.978 1.967l-8.38-1.085a4.003 4.003 0 0 1-1.486-.503l-.173-.1"
      fill="none"
      stroke="#042D49"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx={49.414}
      cy={59.414}
      r={0.5}
      transform="rotate(45 49.414 59.414)"
      fill="#042D49"
      stroke="#042D49"
    />
    <circle
      cx={54.414}
      cy={54.414}
      r={0.5}
      transform="rotate(45 54.414 54.414)"
      fill="#042D49"
      stroke="#042D49"
    />
    <circle
      cx={58.414}
      cy={61.414}
      r={0.5}
      transform="rotate(45 58.414 61.414)"
      fill="#042D49"
      stroke="#042D49"
    />
  </svg>
);

export default SugarCubes;
