import { SVGProps } from 'react';

const WeightLoss = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={80}
    height={80}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={12}
      y={13}
      width={56}
      height={53}
      rx={11}
      fill="none"
      stroke="#042D49"
      strokeWidth={1.5}
      strokeLinejoin="round"
    />
    <path
      d="M29 60.5h14m4.5 0H51"
      fill="none"
      stroke="#042D49"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      opacity={0.4}
      d="M39.5 29.5 44 24"
      fill="none"
      stroke="#042D49"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      opacity={0.6}
      d="M39.5 29.5 41 23"
      fill="none"
      stroke="#042D49"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="M39.5 29.5 38 23"
      fill="none"
      stroke="#042D49"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="M40 19c-6.31 0-11.708 3.376-13.924 8.157C24.914 29.662 27.239 32 30 32h20c2.761 0 5.086-2.338 3.925-4.843C51.708 22.376 46.309 19 40 19Z"
      fill="none"
      stroke="#26D9D8"
      strokeWidth={1.5}
    />
  </svg>
);

export default WeightLoss;
