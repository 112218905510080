import { SVGProps } from 'react';

const MetaLogo = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={24}
    height={24}
    fill="current"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    aria-label="Facebook logo"
  >
    <path
      stroke="none"
      d="M23 12.067C23 5.954 18.076 1 12 1S1 5.954 1 12.067C1 17.592 5.022 22.17 10.281 23v-7.734H7.49v-3.2h2.792V9.628c0-2.773 1.642-4.306 4.155-4.306 1.203 0 2.463.217 2.463.217v2.723H15.51c-1.366 0-1.791.854-1.791 1.73v2.075h3.05l-.487 3.198h-2.563V23C18.979 22.17 23 17.592 23 12.067Z"
    />
  </svg>
);

export default MetaLogo;
