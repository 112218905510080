import { SVGProps } from 'react';

const BritishIsles = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0_292_195817"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="40"
      height="40"
    >
      <path d="M0 3.8147e-06H40V40H0V3.8147e-06Z" fill="white" />
    </mask>
    <g mask="url(#mask0_292_195817)">
      <path
        d="M31.7188 3.51563L29.375 1.17188H24.6875L22.3438 5.9375L17.6562 8.28125V12.9688L22.3438 17.6563V22.3438L20 24.6875L22.3438 29.375H17.6562L15.3125 34.1406V38.8281H20L24.6875 36.4844H31.7188L36.4062 31.7188V27.0313L31.7188 24.6875L29.375 20L34.0625 17.6563V15.3125L29.375 10.625V5.9375L31.7188 3.51563Z"
        stroke="#042D49"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.625 17.6562H5.9375V22.3438L3.59375 24.6875L8.28125 29.375L12.9688 27.0312V22.3438L15.3125 20V17.6562L12.9688 15.3125L10.625 17.6562Z"
        stroke="#042D49"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default BritishIsles;
