import { SVGProps } from 'react';

export const InfoCircle = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_5089_532697)">
      <circle cx="12" cy="12" r="11" stroke="#042D49" strokeWidth="1.5" />
      <path
        d="M12.7981 15.8108L12.0869 16.2849C11.8795 16.4232 11.6106 16.2337 11.6711 15.9919L13.1691 10H11.1516L9.5458 16.1022C9.26992 17.1505 10.0606 18.1762 11.1446 18.1762C11.9327 18.1762 12.6113 17.62 12.7658 16.8472L12.9524 15.9142C12.97 15.8264 12.8726 15.7612 12.7981 15.8108Z"
        fill="#042D49"
      />
      <path
        d="M11.8411 6.77191C11.9305 6.40383 12.2202 6.11786 12.5893 6.03321L12.8466 5.97423C13.5712 5.8081 14.2173 6.46257 14.0418 7.18495L13.9099 7.72817C13.8205 8.09625 13.5308 8.38222 13.1616 8.46687L12.9044 8.52585C12.1798 8.69199 11.5337 8.03751 11.7091 7.31513L11.8411 6.77191Z"
        fill="#042D49"
      />
    </g>
    <defs>
      <clipPath id="clip0_5089_532697">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
