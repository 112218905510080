import { SVGProps } from 'react';

const RightArrow = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={24}
    height={24}
    fill="current"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    aria-label="Right arrow"
  >
    <path
      d="M1 12h22m0 0-6.346-7M23 12l-6.346 7"
      stroke="current"
      fill="none"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RightArrow;
